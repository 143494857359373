import React, {} from "react";
import CrowdPlan from "../CrowdPlan/CrowdPlan";
import Scroller from "../Scroller/Scroller";
import {
  StyledCrowdPlans,
  StyledTitle,
  StyledSubtitle,
  StyledPlansContainer,
} from "./style";

const CrowdPlans = ({
  title,
  subtitle,
  plans,
}) => {
  return (
    <StyledCrowdPlans id="crowdPlans">
      <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
      <Scroller>
        <StyledPlansContainer>
          {plans.map((plan, index) => (
            <CrowdPlan
              key={`crowdplan-${index}`}
              {...plan}
            />
          ))}
        </StyledPlansContainer>
      </Scroller>
        
    </StyledCrowdPlans>
  );
};

export default CrowdPlans;
